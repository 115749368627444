import { routes } from 'utils/routing';
import { MARKETS_SLUGS_MAP } from 'utils/transform';

import { EventPromoCardType } from './EventPromoCardsContainer';

type EventsPromoType = Record<string, EventPromoCardType[]>;

const isTestEnv = () => process.env.NEXT_PUBLIC_BUILD_ENV === 'test';

const promoColors = {
  a: '#7F9BEF',
  b: '#7AA6E9',
  c: '#868BF4',
  d: '#72ADD7',
};

export const getPath = (slug: string, filters: any = {}) =>
  routes.events({
    market_slug: MARKETS_SLUGS_MAP[slug as keyof typeof MARKETS_SLUGS_MAP],
    exclude_hidden_events: true,
    ordering: 'session_date',
    ...filters,
  });

export const eventsPromo = (): EventsPromoType => {
  const cincinnatiEvents = [
    {
      backgroundImage: '/static/img/promo-cards/jewish-holidays-events-cincinnati.png',
      backgroundColor: promoColors.a,
      route: routes.jewishHolidaysEvents('cincinnati'),
      title: 'jewishHolidaysEvents',
    },
    {
      backgroundImage: '/static/img/promo-cards/young-adults-events-cincinnati.png',
      backgroundColor: promoColors.b,
      route: routes.youngAdultEvents('cincinnati'),
      title: 'youngAdultsEvents',
    },
    {
      backgroundImage: '/static/img/promo-cards/family-events-cincinnati.png',
      objectPosition: 'top',
      backgroundColor: promoColors.c,
      route: routes.familyEvents('cincinnati'),
      title: 'familyEvents',
    },
  ];
  const detroitEvents = [
    {
      backgroundImage: '/static/img/promo-cards/purim-events-detroit.jpg',
      backgroundColor: promoColors.a,
      route: routes.jewishHolidaysEvents('detroit'),
      title: 'jewishHolidaysEvents',
    },
    {
      backgroundImage: '/static/img/promo-cards/young-adults-events-detroit.jpg',
      backgroundColor: promoColors.b,
      route: routes.youngAdultEvents('detroit'),
      title: 'youngAdultsEvents',
    },
    {
      backgroundImage: '/static/img/promo-cards/family-events-detroit.jpg',
      objectPosition: 'top',
      backgroundColor: promoColors.c,
      route: routes.familyEvents('detroit'),
      title: 'familyEvents',
    },
    {
      backgroundImage: '/static/img/promo-cards/jewish-learning-events.jpg',
      backgroundColor: promoColors.d,
      route: routes.adultLearningEvents('detroit'),
      title: 'adultLearningEvents',
    },
  ];
  const montrealEvents = [
    {
      backgroundImage: '/static/img/promo-cards/purim-events-detroit.jpg',
      backgroundColor: promoColors.a,
      route: routes.jewishHolidaysEvents('montreal'),
      title: 'jewishHolidaysEvents',
    },
    {
      backgroundImage: '/static/img/promo-cards/young-adults-events-montreal.jpg',
      backgroundColor: promoColors.b,
      route: routes.youngAdultEvents('montreal'),
      title: 'youngAdultsEvents',
    },
    {
      backgroundImage: '/static/img/promo-cards/family-events-montreal.jpg',
      backgroundColor: promoColors.c,
      route: routes.familyEvents('montreal'),
      title: 'familyEvents',
    },
    {
      backgroundImage: '/static/img/promo-cards/all-events.jpg',
      backgroundColor: promoColors.d,
      route: routes.events({
        market_slug: MARKETS_SLUGS_MAP.montreal,
        exclude_hidden_events: true,
        ordering: 'session_date',
      }),
      title: 'allEvents',
    },
  ];

  // Removing 1 element in order to have 3 elements on dev env only
  if (isTestEnv()) {
    montrealEvents.pop();
  }
  return {
    cincinnati: cincinnatiEvents,
    detroit: detroitEvents,
    montreal: montrealEvents,
  };
};

export const bannerData = {
  cincinnati: [
    {
      mobileImage: '/static/img/banners/older-adults-mobile.png',
      desktopImage: '/static/img/banners/older-adults-desktop.png',
      url: { link: 'https://jlive.app/e/cincinnati/older-adults' },
      startAt: '01 Mar 2023',
    },
    {
      mobileImage: '/static/img/banners/detroitIsrael_mobile.png',
      desktopImage: '/static/img/banners/detroitIsrael_desktop.png',
      url: { link: 'https://jewishcincinnati.givingfuel.com/swords-of-iron-fund' },
      startAt: '01 Mar 2023',
    },
  ],
  detroit: [
    {
      mobileImage: '/static/img/banners/jobsDetroit_desktop.png',
      desktopImage: '/static/img/banners/jobsDetroit_desktop.png',
      url: routes.detroitJobs(),
      startAt: '01 Mar 2023',
    },
    {
      mobileImage: '/static/img/banners/detroitMaccabi.png',
      desktopImage: '/static/img/banners/detroitMaccabi.png',
      url: { link: 'https://jlive.app/organizations/365' },
      startAt: '01 Mar 2023',
    },
    {
      mobileImage: '/static/img/banners/detroitIsraelInCrisis_detroit.png',
      desktopImage: '/static/img/banners/detroitIsraelInCrisis_detroit.png',
      url: { link: 'https://jlive.app/e/detroit/supportisrael' },
      startAt: '01 Mar 2023',
    },
  ],
  montreal: [
    {
      mobileImage: '/static/img/banners/montrealIsraelInCrisis_mobile.png',
      mobileImageFr: '/static/img/banners/montrealIsraelInCrisis_mobile_fr.png',
      desktopImage: '/static/img/banners/montrealIsraelInCrisis_desktop.png',
      desktopImageFr: '/static/img/banners/montrealIsraelInCrisis_desktop_fr.png',
      url: { link: 'https://jlive.app/e/montreal/supportisrael' },
      startAt: '01 Mar 2023',
    },
    {
      mobileImage: '/static/img/banners/montrealVolunteer_mobile_en.png',
      mobileImageFr: '/static/img/banners/montrealVolunteer_mobile_fr.png',
      desktopImage: '/static/img/banners/montrealVolunteer_en.png',
      desktopImageFr: '/static/img/banners/montrealVolunteer_fr.png',
      url: routes.volunteering('montreal'),
      startAt: '01 Mar 2023',
    },
  ],
};

export const tagsRows = {
  montreal: [
    {
      title: 'teenEvents',
      path: getPath('montreal', { target_age: 2 }),
    },
    {
      title: 'parents',
      path: getPath('montreal', { target_age: 9 }),
    },
    {
      title: 'food',
      path: getPath('montreal', { category: 51 }),
    },
    {
      title: 'artsAndCulture',
      path: getPath('montreal', { category: 2 }),
    },
    {
      title: 'sportsAndFitness',
      path: getPath('montreal', { category: 63 }),
    },
    {
      title: 'israel',
      path: getPath('montreal', { category: 55 }),
    },
    {
      title: 'leadership',
      path: getPath('montreal', { category: 64 }),
    },
    {
      title: 'general',
      path: getPath('montreal', { category: 85 }),
    },
  ],
  detroit: [
    {
      title: 'artsAndCulture',
      path: getPath('detroit', { category: 2 }),
    },
    {
      title: 'israel',
      path: getPath('detroit', { category: 55 }),
    },
    {
      title: 'jewishHolidays',
      path: getPath('detroit', { category: 46 }),
    },
    {
      title: 'teens',
      path: getPath('detroit', { target_age: 2 }),
    },
    {
      title: 'volunteerism',
      path: getPath('detroit', { type: isTestEnv() ? 94 : 115 }),
    },
    {
      title: 'health-wellness',
      path: getPath('detroit', { category: 52 }),
    },
    {
      title: 'history',
      path: getPath('detroit', { category: 53 }),
    },
  ],
  cincinnati: [
    {
      title: 'artsAndCulture',
      path: getPath('cincinnati', { category: 2 }),
    },
    {
      title: 'israel',
      path: getPath('cincinnati', { category: 55 }),
    },
    {
      title: 'jewishHolidays',
      path: getPath('cincinnati', { category: 46 }),
    },
    {
      title: 'teens',
      path: getPath('cincinnati', { target_age: 2 }),
    },
    {
      title: 'volunteerism',
      path: getPath('cincinnati', { type: isTestEnv() ? 94 : 115 }),
    },
    {
      title: 'health-wellness',
      path: getPath('cincinnati', { category: 52 }),
    },
    {
      title: 'history',
      path: getPath('cincinnati', { category: 53 }),
    },
  ],
};
